import { useCallback } from "react";
import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import {
  AUTO_PERFORM_TOOLS,
  DEFAULT_ACTIVE_TOOLS,
  DEFAULT_TOOLS,
  TOOL_IDS,
} from "../consts/tools.consts";
import { MIN_CLIENT_WIDTH_PX } from "pages/viewer/dicomViewer.consts";
import { getFormattedModeOption } from "../functions/formatModeOptions";
import { ToolConfig } from "../tools.types";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { useAutoPerform } from "./useAutoPerform";
import { useInitialPerform } from "./useInitialPerform";
import { useDeactivationPerform } from "./useDeactivationPerform";
import { XcaliberAnalysis } from "pages/viewer/dicomViewer.types";
import { useToolSelection } from "./useToolSelection";

export const useActiveTools = (
  toolConfig: ToolConfig[],
  xcaliberAnalysis: XcaliberAnalysis
) => {
  const {
    dispatch,
    state: { activeToolIds: activeToolIds },
  } = useViewerContext();
  const { deselectToolByMouseButtonMask, deselectTool, selectTool } =
    useToolSelection();
  const autoPerform = useAutoPerform();
  const initialPerform = useInitialPerform();
  const onDeactivation = useDeactivationPerform();
  const onToolButtonClick = useCallback(
    (clickedToolId: string) => {
      const selectedToolId = activeToolIds.includes(clickedToolId)
        ? null
        : clickedToolId;

      let activeTools = [];
      const enabledElementsArray = cornerstone.getEnabledElements();
      if (clickedToolId === TOOL_IDS.MPR_TOOL) {
        const isMPRActive = !!selectedToolId;
        dispatch({
          type: "TOGGLE_MPR_ACTIVE",
          payload: { isActive: isMPRActive },
        });
      }
      enabledElementsArray.forEach((enabledElement) => {
        if (selectedToolId === null) {
          onDeactivation(clickedToolId, enabledElement);
          deselectTool(clickedToolId, enabledElement.element);
        } else {
          // we don't need to set auto performing tools to active/inactive - because they happen then are done
          // They aren't toggled states
          if (!AUTO_PERFORM_TOOLS.includes(selectedToolId)) {
            activeTools = [...activeToolIds, selectedToolId];
          }
          if (enabledElement.element.clientWidth > MIN_CLIENT_WIDTH_PX) {
            if (AUTO_PERFORM_TOOLS.includes(selectedToolId)) {
              autoPerform(selectedToolId, enabledElement);
            } else {
              deselectToolByMouseButtonMask(
                selectedToolId,
                enabledElement.element
              );
              selectTool(selectedToolId, enabledElement.element);
              initialPerform(selectedToolId, enabledElement);
            }
          }
        }
      });
    },
    [
      activeToolIds,
      toolConfig,
      dispatch,
      onDeactivation,
      xcaliberAnalysis,
      selectTool,
      deselectToolByMouseButtonMask,
      deselectTool,
    ]
  );

  return { onToolButtonClick };
};
