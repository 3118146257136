import { useCallback, useEffect, useMemo, useState } from "react";
import { DIRECTIONS, TOOL_IDS } from "../../consts/tools.consts";
import { useFramedModality } from "pages/viewer/hooks/useFramedModality";
import { useScrubImage } from "./useScrubImage";
import { useScrubFrame } from "./useScrubFrame";
import { useViewerContext } from "pages/viewer/context/viewer.context";

const FRAMED_MODALITY_SCROLL_PARAMS = {
  scrollDelta: 10,
};

const STACK_SCROLL_PARAMS = {
  scrollDelta: 250,
};
export const useScrollImageStack = (
  imageStackLength: number,
  onChangeActiveViewport: (newViewportIndex: number) => void
) => {
  const {
    state: { viewports, activeToolIds },
  } = useViewerContext();
  const { isFramedModality } = useFramedModality();
  const scrollParams = useMemo(
    () =>
      isFramedModality ? FRAMED_MODALITY_SCROLL_PARAMS : STACK_SCROLL_PARAMS,
    [isFramedModality]
  );
  const [wheelDelta, setWheelDelta] = useState<number>(0);
  const [isStackScrollBlocked, setIsStackScrollBlocked] =
    useState<boolean>(false);

  const scrubImage = useScrubImage(imageStackLength, onChangeActiveViewport);
  const scrubFrame = useScrubFrame();

  const handleChangeEvent = useCallback(
    (direction: string) => {
      if (isFramedModality) {
        scrubFrame(direction);
      } else {
        scrubImage(direction);
      }
    },
    [isFramedModality, scrubImage]
  );

  useEffect(() => {
    if (isStackScrollBlocked) {
      setWheelDelta(0);
    } else if (wheelDelta !== 0) {
      if (wheelDelta <= -1 * scrollParams.scrollDelta) {
        handleChangeEvent(DIRECTIONS.FORWARD);
        setWheelDelta(0);
      } else if (wheelDelta >= scrollParams.scrollDelta) {
        handleChangeEvent(DIRECTIONS.BACKWARD);
        setWheelDelta(0);
      }
    }
  }, [isStackScrollBlocked, wheelDelta, setWheelDelta, handleChangeEvent]);

  const handleMouseWheel = useCallback(
    (event: WheelEvent) => {
      if (viewports.length === 1) {
        // @ts-ignore
        const { wheelDeltaY, target } = event;
        if (
          target instanceof HTMLElement &&
          target.classList.contains("cornerstone-canvas")
        ) {
          setWheelDelta((prev) => prev + wheelDeltaY);
        }
      }
    },
    [setWheelDelta, viewports]
  );

  const blockStackScroll = useCallback(
    (event: MouseEvent) => {
      if (event.button === 1) {
        const timeout = event.type === "mouseup" ? 200 : 0;
        setTimeout(() => {
          setIsStackScrollBlocked((prev) => !prev);
        }, timeout);
      }
    },
    [setIsStackScrollBlocked]
  );

  const registerScrollBlockerEvents = useCallback(() => {
    document.addEventListener("mousedown", blockStackScroll, {
      passive: true,
    });
    document.addEventListener("mouseup", blockStackScroll, {
      passive: true,
    });
    return () => {
      document.removeEventListener("mouseup", blockStackScroll);
      document.removeEventListener("mousedown", blockStackScroll);
    };
  }, [blockStackScroll]);

  const registerStackScrollToolEvents = useCallback(() => {
    if (imageStackLength) {
      document.addEventListener("wheel", handleMouseWheel, {
        passive: true,
      });
      return () => {
        document.removeEventListener("wheel", handleMouseWheel);
      };
    }
  }, [handleMouseWheel, imageStackLength]);

  useEffect(() => {
    if (
      activeToolIds.includes(TOOL_IDS.STACK_SCROLL_MOUSE_WHEEL) ||
      activeToolIds.includes(TOOL_IDS.STACK_SCROLL_MULTI_TOUCH)
    ) {
      registerStackScrollToolEvents();
      registerScrollBlockerEvents();
    }

    if (
      !activeToolIds.includes(TOOL_IDS.STACK_SCROLL_MOUSE_WHEEL) &&
      !activeToolIds.includes(TOOL_IDS.STACK_SCROLL_MULTI_TOUCH)
    ) {
      document.removeEventListener("wheel", handleMouseWheel);
    }

    return () => {
      document.removeEventListener("wheel", handleMouseWheel);
    };
  }, [registerStackScrollToolEvents, handleMouseWheel, activeToolIds]);
};
