import { useViewerContext } from "pages/viewer/context/viewer.context";
import { useCallback } from "react";
import { getFormattedModeOption } from "../functions/formatModeOptions";
import cornerstoneTools from "cornerstone-tools";
import { TOOL_IDS } from "../consts/tools.consts";

export const useToolSelection = () => {
  const {
    dispatch,
    state: { activeToolIds, initializedTools },
  } = useViewerContext();

  const deselectToolByMouseButtonMask = useCallback(
    (toolId, element) => {
      const selectedTool = initializedTools.find(({ name }) => name === toolId);
      if (selectedTool && toolId !== TOOL_IDS.WWWC) {
        const activeTools = initializedTools.filter(({ name }) =>
          activeToolIds.includes(name)
        );
        const updatedActiveToolIds = [toolId];
        activeTools.forEach((tool) => {
          const formatted = getFormattedModeOption(tool.modeOptions);
          const selectedFormatted = getFormattedModeOption(
            selectedTool.modeOptions
          );
          if (formatted.mouseButtonMask === selectedFormatted.mouseButtonMask) {
            cornerstoneTools.setToolPassiveForElement(element, tool.name, {
              mouseButtonMask: null,
            });
          } else {
            updatedActiveToolIds.push(tool.name);
          }
        });

        dispatch({
          type: "SET_ACTIVE_TOOLS",
          payload: { activeToolIds: updatedActiveToolIds },
        });
      }
    },
    [activeToolIds, initializedTools, dispatch]
  );

  const deselectTool = useCallback(
    (toolId, element) => {
      if (toolId !== TOOL_IDS.WWWC) {
        cornerstoneTools.setToolPassiveForElement(element, toolId, {
          mouseButtonMask: null,
        });
      }

      dispatch({
        type: "SET_ACTIVE_TOOLS",
        payload: { activeToolIds: activeToolIds.filter((id) => id !== toolId) },
      });
    },
    [activeToolIds, dispatch]
  );

  const selectTool = useCallback(
    (toolId, element) => {
      const selectedTool = initializedTools.find(({ name }) => name === toolId);
      if (selectedTool) {
        const selectedFormatted = getFormattedModeOption(
          selectedTool.modeOptions
        );
        // We don't want WWWC to be set to active because we have other handlers for it
        if (toolId !== TOOL_IDS.WWWC) {
          cornerstoneTools.setToolActiveForElement(
            element,
            toolId,
            selectedFormatted
          );
        }

        dispatch({
          type: "ADD_ACTIVE_TOOL",
          payload: { addedToolId: toolId },
        });
      }
    },
    [activeToolIds, initializedTools, dispatch]
  );
  return { deselectTool, deselectToolByMouseButtonMask, selectTool };
};
