import { useViewerContext } from "pages/viewer/context/viewer.context";
import { useFramedModality } from "pages/viewer/hooks/useFramedModality";
import { useMemo } from "react";
import { TOOL_IDS } from "../consts/tools.consts";

export const usePlayPauseFrames = () => {
  const {
    state: { activeToolIds },
  } = useViewerContext();
  const { isFramedModality } = useFramedModality();
  return useMemo(
    () => isFramedModality && activeToolIds.includes(TOOL_IDS.PLAY_FRAMES),
    [activeToolIds, isFramedModality]
  );
};
