import cornerstone from "cornerstone-core";
import { useEffect, useMemo, useState } from "react";
import cornerstoneTools from "cornerstone-tools";
import { TOOL_IDS } from "../consts/tools.consts";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { getFormattedModeOption } from "../functions/formatModeOptions";

export const useIsMouseWheelDown = () => {
  const {
    state: { activeToolIds, initializedTools },
  } = useViewerContext();
  const [isMouseWheelDown, setIsMouseWheelDown] = useState<boolean>(false);

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (event.button === 1) {
        setIsMouseWheelDown(true);
      }
    };

    const handleMouseUp = (event: MouseEvent) => {
      if (event.button === 1) {
        setIsMouseWheelDown(false);
      }
    };

    const handleMouseLeave = () => {
      setIsMouseWheelDown(false);
    };

    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  const selectedLeftClick = useMemo(() => {
    const activeTools = initializedTools.filter(({ name }) =>
      activeToolIds.includes(name)
    );
    const leftClickTool = activeTools.flatMap((tool) => {
      const formatted = getFormattedModeOption(tool.modeOptions);
      if (formatted.mouseButtonMask === 1) {
        return tool.name;
      }
      return [];
    });
    return leftClickTool.length ? leftClickTool[0] : null;
  }, [activeToolIds, initializedTools]);

  useEffect(() => {
    if (activeToolIds.includes(TOOL_IDS.WWWC)) {
      const enabledElementsArray = cornerstone.getEnabledElements();
      enabledElementsArray.forEach(({ element }) => {
        if (isMouseWheelDown) {
          const selectedFormatted = getFormattedModeOption({
            mouseButtonMask: 1,
          });

          cornerstoneTools.setToolActiveForElement(
            element,
            TOOL_IDS.WWWC,
            selectedFormatted
          );
          const wwwcTool = cornerstoneTools.getToolForElement(
            element,
            TOOL_IDS.WWWC
          );
          if (wwwcTool) {
            wwwcTool.setDragEvent(element);
          }
        } else {
          cornerstoneTools.setToolPassiveForElement(element, TOOL_IDS.WWWC, {
            mouseButtonMask: null,
          });
          const wwwcTool = cornerstoneTools.getToolForElement(
            element,
            TOOL_IDS.WWWC
          );
          if (wwwcTool) {
            wwwcTool.disableDragEvent(element);
          }
          if (selectedLeftClick) {
            cornerstoneTools.setToolActiveForElement(
              element,
              selectedLeftClick,
              {
                mouseButtonMask: 1,
              }
            );
          }
        }
      });
    }
  }, [isMouseWheelDown, activeToolIds, selectedLeftClick]);
};
