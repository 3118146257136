import { useCallback, useEffect, useMemo } from "react";
import cornerstoneTools from "cornerstone-tools";
import { DEFAULT_ACTIVE_TOOLS, TOOL_IDS } from "../consts/tools.consts";
import { EraserTool } from "../default-tools/EraserTool";
import CervicalLabel from "../spinal-labels/CervicalLabel";
import LumbarLabel from "../spinal-labels/LumbarLabel";
import ThoraticLabel from "../spinal-labels/ThoraticLabel";
import { CentreLine } from "../measurement-tools/CentreLine";
import { VFlip } from "../manipulation-tools/VFlip";
import { HFlip } from "../manipulation-tools/HFlip";
import { Invert } from "../manipulation-tools/Invert";
import { Pointer } from "../default-tools/Pointer";
import LengthTool from "../measurement-tools/LengthTool";
import RectangularArea from "../measurement-tools/RectangularArea";
import EllipticalArea from "../measurement-tools/EllipticalArea";
import { getFormattedModeOption } from "../functions/formatModeOptions";
import { CentrePoint } from "../measurement-tools/CentrePoint";
import { CurveRadius } from "../measurement-tools/curve-radius/CurveRadius";
import { DARA } from "../miscellaneous-tools/DARA";
import { HipDistractionIndex } from "../miscellaneous-tools/hip-distraction-index/HipDistractionIndex";
import { VertebralHeartScore } from "../miscellaneous-tools/VertebralHeartScore";
import { GeorgesLine } from "../miscellaneous-tools/GeorgesLine";
import { GonsteadTool } from "../miscellaneous-tools/gonstead-tool/GonsteadTool";
import { XcaliberTool } from "../miscellaneous-tools/xcaliber-tool/XcaliberTool";
import FreeHandArea from "../measurement-tools/FreehandArea";
import { TrueVertical } from "../measurement-tools/true-vertical/TrueVertical";
import { TrueHorizontal } from "../measurement-tools/true-horizontal/TrueHorizontal";
import { StraightLine } from "../measurement-tools/straight-line/StraightLine";
import { MPRTool } from "../mpr-tool/MPRTool";
import CobbAngleTool from "../measurement-tools/cobb-angle/CobbAngle";
import AngleTool from "../measurement-tools/angle/AngleTool";
import { useToolConfigForUser } from "./useToolConfigForUser";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import WwwcTool from "../manipulation-tools/WWWCTool";

export const useInitializeTools = () => {
  const { dispatch } = useViewerContext();
  const toolConfigs = useToolConfigForUser(true);
  const initializedTools = useMemo(
    () =>
      toolConfigs.map((toolConfig) => {
        const { name } = toolConfig;
        if (name) {
          const customTool = initializeCustomTool(name);
          if (customTool) {
            return {
              ...toolConfig,
              toolClass: customTool,
            };
          }
        }

        return { ...toolConfig };
      }),
    [toolConfigs]
  );

  // Tools have to be implemented/added on element enabled callback in react-cornerstone-viewpoint
  // Otherwise they won't show up
  const addToolCallback = useCallback(
    (element) => {
      if (element) {
        initializedTools.forEach(({ name, modeOptions, toolClass }) => {
          const formatted = getFormattedModeOption(modeOptions);
          if (toolClass) {
            cornerstoneTools.addToolForElement(element, toolClass);
          } else {
            const tool = getStandardToolById(name);
            if (tool) {
              cornerstoneTools.addToolForElement(element, tool);
            }
          }
          toggleDefaultToolsActive(name, formatted, element, true);
        });
        dispatch({
          type: "SET_ACTIVE_TOOLS",
          payload: { activeToolIds: DEFAULT_ACTIVE_TOOLS },
        });
      } else {
        console.warn("invalid element ref while trying to add tools");
      }
    },
    [initializedTools, cornerstoneTools]
  );

  useEffect(() => {
    dispatch({
      type: "SET_INITIALIZED_TOOL_CONFIGS",
      payload: { initializedTools },
    });
  }, [dispatch, initializedTools]);

  return { addToolCallback, initializedTools };
};

const initializeCustomTool = (name: string) => {
  switch (name) {
    case TOOL_IDS.CENTER_LINE:
      return CentreLine;
    case TOOL_IDS.CURVE_RADIUS:
      return CurveRadius;
    case TOOL_IDS.CENTER_POINT:
      return CentrePoint;
    case TOOL_IDS.FREE_HAND_AREA:
      return FreeHandArea;
    case TOOL_IDS.ANGLE:
      return AngleTool;
    case TOOL_IDS.COBB_ANGLE:
      return CobbAngleTool;
    case TOOL_IDS.DARA:
      return DARA;
    case TOOL_IDS.HIP_DISTRACTION_INDEX:
      return HipDistractionIndex;
    case TOOL_IDS.VERTEBRAL_HEART_SCORE:
      return VertebralHeartScore;
    case TOOL_IDS.CERVICAL_LABEL:
      return CervicalLabel;
    case TOOL_IDS.THORATIC_LABEL:
      return ThoraticLabel;
    case TOOL_IDS.LUMBAR_LABEL:
      return LumbarLabel;
    case TOOL_IDS.GEORGES_LINE:
      return GeorgesLine;
    case TOOL_IDS.GONSTEAD_TOOL:
      return GonsteadTool;
    case TOOL_IDS.XCALIBER_TOOL:
      return XcaliberTool;
    case TOOL_IDS.WWWC:
      return WwwcTool;
    case TOOL_IDS.ERASER:
      return EraserTool;
    case TOOL_IDS.POINTER:
      return Pointer;
    case TOOL_IDS.VFLIP:
      return VFlip;
    case TOOL_IDS.HFLIP:
      return HFlip;
    case TOOL_IDS.INVERT:
      return Invert;
    case TOOL_IDS.LENGTH_TOOL:
      return LengthTool;
    case TOOL_IDS.RECTANGULAR_AREA:
      return RectangularArea;
    case TOOL_IDS.ELLIPTICAL_AREA:
      return EllipticalArea;
    case TOOL_IDS.TRUE_VERTICAL:
      return TrueVertical;
    case TOOL_IDS.TRUE_HORIZONTAL:
      return TrueHorizontal;
    case TOOL_IDS.STRAIGHT_LINE:
      return StraightLine;
    case TOOL_IDS.MPR_TOOL:
      return MPRTool;
    default:
      // Handle case where no matching custom tool is found
      return null;
  }
};

// The resulting STANDARD_CORNERSTONE_TOOLS object
const STANDARD_CORNERSTONE_TOOLS_OBJECT = {
  WWWC: "Wwwc",
  ZOOM_MOUSE_WHEEL: "ZoomMouseWheel",
  MAGNIFY: "Magnify",
  ARROW_ANNOTATE: "ArrowAnnotate",
  PAN: "Pan",
  ROTATE: "Rotate",
  PAN_MULTI_TOUCH: "PanMultiTouch",
  ZOOM_TOUCH_PINCH: "ZoomTouchPinch",
  STACK_SCROLL_MOUSE_WHEEL: "StackScrollMouseWheel",
  STACK_SCROLL_MULTI_TOUCH: "StackScrollMultiTouch",
  ZOOM: "Zoom",
  PIXEL_PROBE: "Probe",
};

const getStandardToolById = (toolId: string) => {
  switch (toolId) {
    case STANDARD_CORNERSTONE_TOOLS_OBJECT.ZOOM_MOUSE_WHEEL:
      return cornerstoneTools.ZoomMouseWheelTool;
    case STANDARD_CORNERSTONE_TOOLS_OBJECT.MAGNIFY:
      return cornerstoneTools.MagnifyTool;
    case STANDARD_CORNERSTONE_TOOLS_OBJECT.ARROW_ANNOTATE:
      return cornerstoneTools.ArrowAnnotateTool;
    case STANDARD_CORNERSTONE_TOOLS_OBJECT.PAN:
      return cornerstoneTools.PanTool;
    case STANDARD_CORNERSTONE_TOOLS_OBJECT.PAN_MULTI_TOUCH:
      return cornerstoneTools.PanMultiTouchTool;
    case STANDARD_CORNERSTONE_TOOLS_OBJECT.ZOOM_TOUCH_PINCH:
      return cornerstoneTools.ZoomTouchPinchTool;
    case STANDARD_CORNERSTONE_TOOLS_OBJECT.STACK_SCROLL_MOUSE_WHEEL:
      return cornerstoneTools.StackScrollMouseWheelTool;
    case STANDARD_CORNERSTONE_TOOLS_OBJECT.STACK_SCROLL_MULTI_TOUCH:
      return cornerstoneTools.StackScrollMultiTouchTool;
    case STANDARD_CORNERSTONE_TOOLS_OBJECT.ZOOM:
      return cornerstoneTools.ZoomTool;
    case STANDARD_CORNERSTONE_TOOLS_OBJECT.ROTATE:
      return cornerstoneTools.RotateTool;
    case STANDARD_CORNERSTONE_TOOLS_OBJECT.PIXEL_PROBE:
      return cornerstoneTools.ProbeTool;
    default:
      return null;
  }
};

const toggleDefaultToolsActive = (
  name: string,
  modeOptions,
  element,
  toggleActive = true
) => {
  if (DEFAULT_ACTIVE_TOOLS.includes(name)) {
    if (toggleActive) {
      cornerstoneTools.setToolActiveForElement(element, name, modeOptions);
    } else {
      cornerstoneTools.setToolPassiveForElement(element, name, {
        mouseButtonMask: null,
      });
    }
  }
};
