import { Typography, Box, CssBaseline, Grid } from "@mui/material";
import { GetStartedButton } from "../../components/buttons/Buttons";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import { useDarkModeTheme } from "hooks/useDarkmodeTheme";
import { AppHeader } from "components/header/AppHeader";

// This page is made for google verification
export const LandingPageWithDescription = () => {
  const { textThemeColor } = useDarkModeTheme();

  return (
    <>
      <meta
        name="google-site-verification"
        content="JCrcoY-G98_2AWcUMZzQziLyMlpcox3bXOB-kH1C7-U"
      />
      <CssBaseline />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.25 }}
        exit={{ opacity: 0 }}
      >
        <AppHeader />
        <Box display="flex" alignItems="center" maxWidth="false">
          <Box
            style={{
              position: "absolute",
              left: "55%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography
              style={{ color: textThemeColor }}
              variant="h2"
              align="left"
              color="textPrimary"
              gutterBottom
            >
              iTX Cloud PACS
            </Typography>
            <Typography
              style={{ color: textThemeColor }}
              align="left"
              color="textSecondary"
              paragraph
            >
              Welcome to iTX Cloud Pacs iTX Cloud Pacs is designed to Share,
              store and annotate X-ray studies using the cloud Why We Need
              Access to Your Google Data To provide the best experience, iTX
              Cloud Pacs requests access to google docs so we can: Allow you to
              create new reports via google docs and customized document
              templates. Allow you to edit and view existing reports. Your data
              is securely handled, and we comply with Google's policies. For
              more details, read our{" "}
              <a href="/privacy-policy">Privacy Policy.</a>
            </Typography>

            <a
              href="https://atx.com.au/terms-and-conditions-x-caliber/"
              target="_blank"
            >
              <Typography
                style={{ color: textThemeColor, fontSize: "0.8rem" }}
                align="left"
                color="textSecondary"
                paragraph
              >
                X Caliber Terms and Conditions
              </Typography>
            </a>
            <a href="/privacy-policy" target="_blank">
              <Typography
                style={{ color: textThemeColor, fontSize: "0.8rem" }}
                align="left"
                color="textSecondary"
              >
                Privacy Policy
              </Typography>
            </a>
            <br />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.25 }}
              exit={{ opacity: 0 }}
            >
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item>
                  <Link to="/login" style={{ textDecoration: "none" }}>
                    <GetStartedButton>Get Started</GetStartedButton>
                  </Link>
                </Grid>
              </Grid>
            </motion.div>
          </Box>
        </Box>
      </motion.div>
    </>
  );
};
