import { CssBaseline, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";

export const PrivacyPolicy = () => {
  return (
    <Container component="main" maxWidth="xl">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ color: "black" }}>
          iTX Cloud PACS Privacy Policy
        </Typography>

        <Typography sx={{ color: "black" }}>
          Privacy Policy Effective Date: 14/01/2025 1. Introduction Welcome to
          ITX Viewer ("we," "our," or "us"). Your privacy is important to us,
          and we are committed to protecting the personal information you share
          with us. This Privacy Policy outlines how we collect, use, disclose,
          and safeguard your information when you visit our website
          itxviewer.com (the "Site"). By accessing or using the Site, you agree
          to the terms of this Privacy Policy. If you do not agree, please do
          not use the Site. 2. Information We Collect We may collect the
          following types of information: Personal Information: Name, email
          address, phone number, and other contact details you provide when you
          fill out forms or communicate with us. Non-Personal Information: IP
          address, browser type, operating system, and other technical data
          collected automatically through cookies or similar technologies. Usage
          Data: Information about your interactions with the Site, such as pages
          visited, time spent on pages, and clicks. 3. How We Use Your
          Information We use the information we collect for the following
          purposes: To provide, operate, and maintain the Site. To improve your
          experience and personalize content. To communicate with you about
          updates, promotions, or services. To monitor and analyze usage trends
          and improve the Site’s functionality. To ensure security and prevent
          fraudulent activities. 4. Sharing Your Information We do not sell your
          personal information. However, we may share your information with:
          Service Providers: Third parties that assist us in operating the Site,
          such as hosting providers, analytics services, and customer support.
          Legal Requirements: When required by law or to respond to legal
          processes, subpoenas, or governmental requests. Business Transfers: In
          connection with a merger, sale, or transfer of assets, your
          information may be transferred to the new entity. 5. Cookies and
          Tracking Technologies We use cookies and similar technologies to:
          Enhance your browsing experience. Analyze website traffic and usage
          patterns. Provide tailored advertising. You can manage your cookie
          preferences through your browser settings. Please note that disabling
          cookies may affect the Site’s functionality. 6. Data Retention We
          retain your personal information only as long as necessary to fulfill
          the purposes outlined in this Privacy Policy unless a longer retention
          period is required by law. 7. Security We implement reasonable
          administrative, technical, and physical measures to protect your
          information. However, no method of transmission or storage is entirely
          secure, and we cannot guarantee absolute security. 8. Your Rights
          Depending on your jurisdiction, you may have the following rights:
          Access your personal information. Request corrections to inaccurate
          information. Delete your personal data. Object to the processing of
          your data. Withdraw consent where applicable. To exercise your rights,
          contact us at admin@atx.com. 9. Third-Party Links The Site may contain
          links to third-party websites. We are not responsible for the privacy
          practices or content of these websites. We encourage you to review
          their privacy policies. 10. Changes to This Privacy Policy We may
          update this Privacy Policy periodically. Changes will be posted on
          this page with an updated effective date. Your continued use of the
          Site after changes are posted constitutes your acceptance of the
          revised Privacy Policy. 11. Contact Us If you have questions or
          concerns about this Privacy Policy or our data practices, please
          contact us at: admin@atx.com
        </Typography>
      </Box>
    </Container>
  );
};
