import { Typography, Box, CssBaseline, Grid } from "@mui/material";
import { GetStartedButton } from "../../components/buttons/Buttons";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { DarkModeSwitch } from "../../components/darkmode-switch/DarkmodeSwitch";
import { useDarkModeTheme } from "hooks/useDarkmodeTheme";

export const LandingPage = () => {
  const { textThemeColor, isDarkModeOn } = useDarkModeTheme();

  return (
    <>
      <meta
        name="google-site-verification"
        content="JCrcoY-G98_2AWcUMZzQziLyMlpcox3bXOB-kH1C7-U"
      />
      <CssBaseline />
      <DarkModeSwitch />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.25 }}
        exit={{ opacity: 0 }}
      >
        <Box display="flex" alignItems="center" maxWidth="false">
          <Box
            style={{
              position: "absolute",
              left: "25%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography
              style={{ color: textThemeColor }}
              variant="h2"
              align="left"
              color="textPrimary"
              gutterBottom
            >
              iTX Cloud PACS
            </Typography>
            <Typography
              style={{ color: textThemeColor }}
              variant="h5"
              align="left"
              color="textSecondary"
              paragraph
            >
              Share &amp; store X-ray studies using the cloud
            </Typography>
            <Typography
              style={{ color: textThemeColor }}
              align="left"
              color="textSecondary"
              paragraph
            >
              <img
                src={`../assets/images/logos/x-caliber${
                  isDarkModeOn ? "-dark" : ""
                }.png`}
                style={{ height: "2rem" }}
              />{" "}
              New AI diagnostic tool is available now in your viewer (Vets only)
            </Typography>

            <a
              href="https://atx.com.au/terms-and-conditions-x-caliber/"
              target="_blank"
            >
              <Typography
                style={{ color: textThemeColor, fontSize: "0.8rem" }}
                align="left"
                color="textSecondary"
                paragraph
              >
                X Caliber Terms and Conditions
              </Typography>
            </a>
            <a href="/privacy-policy" target="_blank">
              <Typography
                style={{ color: textThemeColor, fontSize: "0.8rem" }}
                align="left"
                color="textSecondary"
              >
                Privacy Policy
              </Typography>
            </a>
            <br />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.25 }}
              exit={{ opacity: 0 }}
            >
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item>
                  <Link to="/login" style={{ textDecoration: "none" }}>
                    <GetStartedButton>Get Started</GetStartedButton>
                  </Link>
                </Grid>
              </Grid>
            </motion.div>
          </Box>
        </Box>
      </motion.div>
    </>
  );
};
