import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const NON_REDIRECT_ROUTES = ["login", "privacy-policy", "viewer"];
export const useSavedRoute = () => {
  const location = useLocation();
  const [savedRoute, setSavedRoute] = useState<string | null>(null);
  useEffect(() => {
    if (location.state) {
      const {
        state: { redirectUrl },
      } = location || { state: {} };
      if (!redirectUrl) {
        setSavedRoute(null);
      } else {
        if (
          NON_REDIRECT_ROUTES.every((url) => redirectUrl.indexOf(url) === -1) &&
          redirectUrl !== ""
        ) {
          const routeWithoutRoot = redirectUrl.replace(
            window.location.origin,
            ""
          );
          setSavedRoute(routeWithoutRoot);
        }
      }
    }
  }, [location, setSavedRoute]);
  return savedRoute;
};
