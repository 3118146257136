import { useAppContext } from "context/app.context";
import { useCallback, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { getGoogleTokens } from "services/auth";

export const useSetGoogleToken = () => {
  const {
    state: { userId },
  } = useAppContext();

  const getGoogleToken = useCallback(async () => {
    if (userId) {
      const tokenResult = await getGoogleTokens(userId);
    
      if (tokenResult) {
        secureLocalStorage.setItem("googleAccessToken", {
          googleAccessToken: tokenResult,
        });
      }
    }
  }, [userId]);

  useEffect(() => {
    getGoogleToken();
  }, [userId]);
};
