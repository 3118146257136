import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import { EVENTS } from "../consts/tools.consts";

const BaseTool = cornerstoneTools.importInternal("base/BaseTool");

const { wwwcCursor } = cornerstoneTools.import("tools/cursors");

export default class WwwcTool extends BaseTool {
  constructor(props = {}) {
    const defaultProps = {
      name: "Wwwc",
      strategies: { basicLevelingStrategy },
      supportedInteractionTypes: ["Mouse", "Touch"],
      configuration: {
        orientation: 0,
      },
      svgCursor: wwwcCursor,
    };

    super(props, defaultProps);
  }
  setDragEvent(element) {
    element.addEventListener(EVENTS.MOUSE_DRAG, this.mouseDragCallback);
    cornerstone.updateImage(element);
  }
  disableDragEvent(element) {
    element.removeEventListener(EVENTS.MOUSE_DRAG, this.mouseDragCallback);
    cornerstone.updateImage(element);
  }

  mouseDragCallback(evt) {
    basicLevelingStrategy(evt);
    cornerstone.setViewport(evt.detail.element, evt.detail.viewport);
  }

  touchDragCallback(evt) {
    // Prevent CornerstoneToolsTouchStartActive from killing any press events
    evt.stopImmediatePropagation();
    basicLevelingStrategy(evt);
    cornerstone.setViewport(evt.detail.element, evt.detail.viewport);
  }
}

/**
 * Here we normalize the ww/wc adjustments so the same number of on screen pixels
 * adjusts the same percentage of the dynamic range of the image.  This is needed to
 * provide consistency for the ww/wc tool regardless of the dynamic range (e.g. an 8 bit
 * image will feel the same as a 16 bit image would)
 *
 * @param {Object} evt
 * @param {Object} { orienttion }
 * @returns {void}
 */
function basicLevelingStrategy(evt) {
  const orientation = 0;
  const eventData = evt.detail;

  const maxVOI =
    eventData.image.maxPixelValue * eventData.image.slope +
    eventData.image.intercept;
  const minVOI =
    eventData.image.minPixelValue * eventData.image.slope +
    eventData.image.intercept;
  const imageDynamicRange = maxVOI - minVOI;
  const multiplier = imageDynamicRange / 1024;

  const deltaX = eventData.deltaPoints.page.x * multiplier;
  const deltaY = eventData.deltaPoints.page.y * multiplier;

  if (orientation === 0) {
    eventData.viewport.voi.windowWidth += deltaX;
    eventData.viewport.voi.windowCenter += deltaY;
  } else {
    eventData.viewport.voi.windowWidth += deltaY;
    eventData.viewport.voi.windowCenter += deltaX;
  }

  // Unset any existing VOI LUT
  eventData.viewport.voiLUT = undefined;
}
